import React from "react";
import "./whatisled.css";
import whatisled from "../../images/whatisled.png";
import whatisledtwo from "../../images/whatisled2.png";

function Whatisled() {
  return (
    <section>
      <div className="whatIsLed">
        <div className="container">
          <div className="whatIsLedContent">
            <div className="whatIsLedContentOne">
              <div className="whatIsLedImag">
                <img className="whatIsLedFirst" src={whatisled} alt="image" />
                <img
                  className="whatIsLedSecond"
                  src={whatisledtwo}
                  alt="image"
                />
              </div>
              <div className="whatIsLedText">
                <h3>What is LEDs Go?</h3>
                <p>
                  Leds Go is a platform for commercial video placement on LED
                  monitors in the Dubai emirate.
                </p>
              </div>
            </div>
            <div className="whatIsLedContentTwo">
              <div className="whatIsLedText">
                <h3>How Does It work</h3>
                <p>
                  LEDs Go allows the advertisers to place advertisement
                  according to spot number only in a few steps and short period
                  of time. This platform is unique and it is completely visible
                  to the client ?
                </p>
              </div>
              <div className="whatIsLedImag">
                
                <img className="whatIsLedFirst" src={whatisled} alt="image" />
                <img
                  className="whatIsLedSecond"
                  src={whatisledtwo}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whatisled;
