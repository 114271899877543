import React, { useState , useEffect} from "react";
import Secondheader from "../components/SecondHeader/Secondheader";
import "./main.css";
import Meta from "../images/meta.png";
import Phone from "../images/phone.png";
import Mail from "../images/mail.png";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { Link, useLocation } from "react-router-dom";

const Conntact = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="uploadContent">
      <Secondheader />
      <div className="privicy">
        <div className="container">
          <div className="contactUs">
            <div className="talkwithUs">
              <h1>Let's talk with us</h1>
              <p>
                Questions, comments, or suggestions? Simply fill in the form and
                we’ll be in touch shortly.
              </p>
              <Link>
                {" "}
                <img src={Meta} alt="image" />
                1055 Arthur ave Elk Groot, 67. New Palmas South Carolina.{" "}
              </Link>
              <Link>
                <img src={Phone} alt="image" /> +1 234 678 9108 99
              </Link>
              <Link>
                <img src={Mail} alt="image" />
                Contact@moralizer.com
              </Link>
            </div>
            <div className="contactInputs">
              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  surname: "",
                  massage: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  } else if (!values.name) {
                    errors.name = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    //   dispatch(getMessage({ values }));
                    setData({ values });
                    setSubmitting(false);
                    resetForm();
                  }, 400);
                }}
              >
                {({ isSubmitting, errors, touched, setFieldValue }) => (
                  <div className="contactInfo">
                    <Form className="contactForm">
                      <label className="contactLabel">Name</label>
                      <Field
                        type="text"
                        name="name"
                        className="nameInput"
                           autoComplete="off"
                        placeholder="Enter your name"
                      />
  {errors.name && touched.name ? (
                        <div className="error">{errors.name}</div>
                      ) : null}
                      <label className="contactLabel">Surname</label>

                      <Field
                        type="text"
                        name="surname"
                        className="nameInput"
                           autoComplete="off"
                        placeholder="Enter your surname"
                      />
                    {errors.surname && touched.surname ? (
                        <div className="error">{errors.surname}</div>
                      ) : null}
                      <label className="contactLabel">Email</label>
                      <Field
                        type="email"
                        name="email"
                        autoComplete="off"
                        className="nameInput "
                        placeholder="Enter your email"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                      <label className="contactLabel">Your Message... </label>
                      <Field
                        component="textarea"
                        className="textareaInput "
                        name="massage"
                        placeholder="Your Message"
                        rows="4"
                      />
                      <div className="cancelSave">
                        <button type="submit" className="contactSave">
                          Send Message
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conntact;
