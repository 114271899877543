import React, { useState } from "react";
import "./settings.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Eye from "../../images/eye.png";
const SetPassword = () => {
  const [profile, setProfile] = useState(3);
  const [data, setData] = useState({});
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirm, setConfirm] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleComfirm = () => setConfirm((value) => !value);
  const toggleNew = () => setNewPassword((value) => !value);
  const toggleProf = (index) => {
    setProfile(index);
  };
  return (
    <div className={profile === 3 ? "content  active-content" : "content"}>
      <Formik
        initialValues={{
          passwordcurrent: "",
          passwordnew: "",
          passwordconf: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.passwordcurrent) {
            errors.passwordcurrent = "Required";
          } else if (!values.passwordnew) {
            errors.passwordnew = "Required";
          } else if (!values.passwordconf) {
            errors.passwordconf = "Required";
          } else if (values.passwordnew !== values.passwordconf) {
            errors.passwordconf = "dont match";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            //   dispatch(getMessage({ values }));
            setData({ values });
            setSubmitting(false);
            resetForm();
          }, 400);
        }}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <div className="profilecontact">
            <h3 className="changePasswordTite">Change Password</h3>
            <Form className="profilform">
              <div className="currentPass">
                <label className="profilLabelPass">Current Password</label>
                <Field
                  type={showPassword ? "text" : "password"}
                  name="passwordcurrent"
                  autoComplete="off"
                  className="profilInput "
                  placeholder="Enter your current password"
                />
                {errors.passwordcurrent && touched.passwordcurrent ? (
                  <div className="error">{errors.passwordcurrent}</div>
                ) : null}
                <img src={Eye} alt="image" onClick={toggleChecked} />
              </div>
              <div className="nameSurname">
                <div className="prof">
                  <label className="profilLabelPass">New Password </label>
                  <Field
                    type={newPassword ? "text" : "password"}
                    name="passwordnew"
                    autoComplete="off"
                    className="profilInput "
                    placeholder="Enter your new password "
                  />
                  {errors.passwordnew && touched.passwordnew ? (
                    <div className="error">{errors.passwordnew}</div>
                  ) : null}
                  <img src={Eye} alt="image" onClick={toggleNew} />
                </div>
                <div className="prof">
                  <label className="profilLabelPass">Confirm Password</label>
                  <Field
                    type={showconfirm ? "text" : "password"}
                    name="passwordconf"
                    autoComplete="off"
                    className="profilInput changeEmail"
                    placeholder="Confirm Password"
                  />
                  {errors.passwordconf && touched.passwordconf ? (
                    <div className="error">{errors.passwordconf}</div>
                  ) : null}
                  <img src={Eye} alt="image" onClick={toggleComfirm} />
                </div>
              </div>

              <div className="cancelSave">
                <button type="submit" className="profilChange">
                  Change Password
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SetPassword;
