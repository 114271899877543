import React from 'react'
import "./settings.css";
import Card from '../../images/card1.jpg'
import Price from '../../images/price.png'
import Clock from '../../images/clock.png';
import Card2 from '../../images/Card3.png'

const Announcment = () => {
  return (
    <div className='announcment'>
      <h3> <span>!</span>For changes, please contact support.</h3>

      <div className='announcmentContent'>
          <div className='announcmentBox'>
            <img className='announcmentBoxImage' src={Card} alt='image'/>
            <div className='announcmentText'>
                <div>
              <p>Zip Yard Dunlaoghaire</p>
              <span className='announceSpan'>Sheikh Mohammed bin Rashid Blvd - Downtown Dubai </span>
              <div className='announcePrice'>
                <img className='announceFirst' src={Price} alt='image'/>
                <span>500$</span>
              </div>
              <div className='announcePrice'>
                <img className='announceSecond'  src={Clock} alt='image'/>
                <span>10.22.2024</span>
              </div>
              </div>
              <button className='announceGreen'>Active</button>
              </div>
          </div>
          <div className='announcmentBox'>
          <img className='announcmentBoxImage' src={Card2} alt='image'/>
            <div className='announcmentText'>
                <div>
              <p>Zip Yard Dunlaoghaire</p>
              <span className='announceSpan'>Sheikh Mohammed bin Rashid Blvd - Downtown Dubai </span>
              <div className='announcePrice'>
                <img className='announceFirst' src={Price} alt='image'/>
                <span>500$</span>
              </div>
              <div className='announcePrice'>
                <img className='announceSecond'  src={Clock} alt='image'/>
                <span>10.22.2024</span>
              </div>
              </div>
              <button className='announceGray'>Completed</button>
              </div>
          </div>

      </div>

    </div>
  )
}

export default Announcment