import React from 'react'
import Secondheader from "../components/SecondHeader/Secondheader";
import './main.css'
import SettingsBlock from '../components/SettingsBlock/SettingsBlock';
const Settings = () => {
  return (
    <div className='videoBlock'>
      <Secondheader/>
      <SettingsBlock/>
    </div>
  )
}

export default Settings
