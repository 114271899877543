import React, { useState } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Eye from "../../images/eye.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EnterPassword = () => {
    const [data, setData] = useState({});
  //   console.log(data)
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirm, setConfirm] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleComfirm = () => setConfirm((value) => !value);
  return (
    <div className="signup">
    <div className="container">
      <Link to={`/`}>
        {" "}
        <img className="singllogo" src={logo} alt="logo" />
      </Link>
    </div>

    <div className="container signUpContent">
      <div className="signUpForm">
        <h1>Enter new password</h1>
        <Formik
          initialValues={{
            company: "",
            name: "",
            email: "",
            message: "",
            phoneNumber: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              //   dispatch(getMessage({ values }));
              setData({ values });
              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <div className="formContainer">
              <Form className="form">
                
                <div className="pass">
                  <label className="label-form">New password</label>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="input-form"
                    placeholder="Enter your password"
                  />
                  <img src={Eye} alt="image" onClick={toggleChecked} />
                </div>
                <div className="pass">
                  <label className="label-form">Confirm password</label>
                  <Field
                    type={showconfirm ? "text" : "password"}
                    name="comfirm"
                    className="input-form "
                    placeholder="Confirm your password"
                  />
                  <img src={Eye} alt="image" onClick={toggleComfirm} />
                </div>
               

                <button type="submit" className="button-form">
                 Enter
                </button>
              </Form>
            </div>
          )}
        </Formik>
       
      </div>
    </div>
  </div>
  )
}

export default EnterPassword
