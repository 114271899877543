import React from "react";
import Receive from "../../images/received.png";
import "./loadvideo.css";
import { Link } from "react-router-dom";

const ThanksFor = () => {
  return (
    <div className="thanksContent">
      <div className="container">
        <div className="receivedVideo">
          <div className="receiveContent">
            <div className="receivedImg">
              <img src={Receive} alt="image" />
            </div>
            <p>
              Thank you for submitting your video for advertisement on our
              platform. We have successfully received your video and it is now
              under review by our moderation team.
            </p>
            <Link to="/">Go Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThanksFor;
