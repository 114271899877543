import React from 'react'
import { Link,Outlet } from "react-router-dom"    

import Footer from '../components/Footer/Footer'

const Layout = () => {
  return (
    <div>
       
        <Outlet/>
        <Footer/>
        
      
    </div>
  )
}

export default Layout
