import React, { useState } from "react";
import Accordion from './Accordion'
import './accordion.css'

function Answers() {


  const [isShowIndex, setIsShowIndex] = useState(0);
  const answers = [
    {
      title: 'Choose the Region',
      subTitle: 'Selecting your region helps us provide the most accurate product options, services, and pricing available in your area.',
    },
    {
        title: 'Choose the Monitors',
        subTitle: 'Selecting your region helps us provide the most accurate product options,',
    },
    {
      title: 'Choose the number of spots',
      subTitle: 'Selecting your region helps us provide the most accurate product options,',
  },
  {
    title: 'Upload the video',
    subTitle: 'Selecting your region helps us provide the most accurate product options,',
},
{
  title: 'Get Approval From the operator',
  subTitle: 'Selecting your region helps us provide the most accurate product options,',
},
  
   
  
 
   
  ];
 
  return (
    <div className="container accordContent">
      <div className="accordion">
        <h3>How to Use?</h3>
      </div>
      {answers.map((item, index) => {
        return (
          <Accordion
            key={index}
            title={item.title}
            subTitle={item.subTitle}
            onClick={() => {
              setIsShowIndex(index === isShowIndex ? null : index);
            }}
            isShow={index === isShowIndex}
          />
        );
      })}
    </div>
  );
}

export default Answers;
