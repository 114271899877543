import React, { useState } from "react";
import "./settings.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SetContact from "./SetContact";
import SetPassword from "./SetPassword";

const SetProfile = () => {
  const [profile, setProfile] = useState(1);
  const [data, setData] = useState({});
  const toggleProf = (index) => {
    setProfile(index);
  };
  return (
    <div className="contentTabs">
      <div className={"profileContent"}>
        <button
          className={profile === 1 ? "profile active " : "profile"}
          onClick={() => toggleProf(1)}
        >
          Profile
        </button>
        <button
          className={profile === 2 ? "profile active " : "profile"}
          onClick={() => toggleProf(2)}
        >
          Contact Information
        </button>
        <button
          className={profile === 3 ? "profile active " : "profile"}
          onClick={() => toggleProf(3)}
        >
          Password{" "}
        </button>
      </div>
      <div className={profile === 1 ? "content  active-content" : "content"}>
        <Formik
          initialValues={{
            company: "",
            name: "",
            surname: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.company) {
              errors.company = "Required";
            } else if (!values.name) {
              errors.name = "Required";
            } else if (!values.surname) {
              errors.surname = "Required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              //   dispatch(getMessage({ values }));
              setData({ values });
              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <div className="profilnames">
              <Form className="profilform">
                <label className="profilLabel">Company Name</label>
                <Field
                  type="text"
                  name="company"
                  className="profilInput largInput"
                  placeholder="Enter your company name"
                />
                {errors.company && touched.company ? (
                  <div className="error">{errors.company}</div>
                ) : null}
                <div className="nameSurname">
                  <div className="prof">
                    <label className="profilLabel">Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="profilInput"
                      placeholder="Enter your name"
                    />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="prof">
                    <label className="profilLabel">Surname</label>

                    <Field
                      type="text"
                      name="surname"
                      className="profilInput"
                      placeholder="Enter your surname"
                    />
                    {errors.surname && touched.surname ? (
                      <div className="error">{errors.surname}</div>
                    ) : null}
                  </div>
                </div>

                <div className="cancelSave">
                  <button type="reset" className="profilCancel">
                    Cancel
                  </button>
                  <button type="submit" className="profilSave">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      {profile === 2 ? <SetContact /> : null}
      {profile === 3 ? <SetPassword /> : null}
    </div>
  );
};

export default SetProfile;
