import React ,{useState}from "react";
import logo from "../../images/logo.png";
import "./header.css";
import { Link } from "react-router-dom";
import MenuBar from '../../images/menu.png'
import Close from '../../images/closewhite.png'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <header>
      <div className="bigContainer ">
        <div className="container ">
          <div className="header"> 
            <Link to='/'>
            <img className="logo" src={logo} alt="logo" />
            </Link>
            <img  className="menuBar" src={MenuBar} alt="menu" onClick={()=> setIsMenuOpen(prev=> !prev)}/>
            <div className={isMenuOpen ? "headerInputs " : 'headerInputs setIsMenuOpenmobile'}>
              <img className={isMenuOpen ? "menuClose closeActiv" : "menuClose " } 
              onClick={()=> setIsMenuOpen(false)} src={Close} alt="image"/>
              {/* <div className="inputElement">
                <input type="text" placeholder="Search" /> <span></span>{" "}
              </div> */}
              <div className="sineInUp">
              <Link to={`/upload`} className="signUp">
                {" "}
                Upload Video
              </Link>
              <Link to={`/signIn`} className="signIn">
                Sign In
              </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
