import React, { useRef, useState } from "react";
import "./loadvideo.css";
import Down from "../../images/down.png";
import Format from "../../images/format.png";
import Length from "../../images/length.png";
import File from "../../images/file.png";
import Resolution from "../../images/resolution.png";
import Close from "../../images/close.png";
import { Link } from "react-router-dom";

const LoadVideo = () => {
  const [file, setFile] = useState();
  const uploadRef = useRef();
  const statusRef = useRef();
  const loadTotalRef = useRef();
  const progressRef = useRef();
  const UploadFile = () => {
    const file = uploadRef.current.files[0];
    setFile(URL.createObjectURL(file));
    var formData = new FormData();
    formData.append("video", file);
    var xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", ProgressHandler, false);
    xhr.addEventListener("load", SuccessHandler, false);
    xhr.addEventListener("error", ErrorHandler, false);
    xhr.addEventListener("abort", AbortHandler, false);
    xhr.open("POST", "fileupload.php");
    xhr.send(formData);
  };

  const ProgressHandler = (e) => {
    // loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
    var percent = (e.loaded / e.total) * 100;
    progressRef.current.value = Math.round(percent);
    statusRef.current.innerHTML = Math.round(percent) + "% ";
  };

  const SuccessHandler = (e) => {
    // statusRef.current.innerHTML = e.target.responseText;
    progressRef.current.value = 0;
  };

  const ErrorHandler = () => {
    statusRef.current.innerHTML = "upload failed!!";
  };
  const AbortHandler = () => {
    statusRef.current.innerHTML = "upload aborted!!";
  };

  return (
    <div className="videoContent">
      <div className="container">
        <div className="loadVideo">
          <h1>Upload Your Video</h1>
          <div className="loadVideoTab">
            <div className="uploadbuttns">
              <span className="checked">
                {" "}
                <img src={Down} alt="imag" />
              </span>{" "}
              <span className="loadVideotext"> Upload video</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="notchecked">2</span>{" "}
              <span className="loadVideotext">Confiramtion</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="notchecked">3</span>
              <span className="loadVideotext">Completes Payment</span>
            </div>
          </div>

          <div className="loadVideoContent">
            <p>
              {" "}
              <img src={Format} alt="image" />
              Format: MP4 (or other supported formats)
            </p>
            <p>
              {" "}
              <img src={Length} alt="image" />
              Length: Maximum 15 seconds
            </p>
            <p>
              {" "}
              <img src={File} alt="image" />
              Max File Size: 50 MB
            </p>
            <p>
              {" "}
              <img src={Resolution} alt="image" />
              Resolution: 1920x1080 pixels
            </p>

            <ul>
              <li>Video must be clear and well-lit.</li>
              <li>Must be appropriate for all audiences</li>
              <li>No excessive effects or distracting branding.</li>
              <li>Ensure clarity and avoid copyrighted content.</li>
            </ul>
            <h3>Upload Video</h3>
            <div className="imgLoad">
              <div className="chooseFile">
                {file ? (
                  <div className="uploadedVideo">
                    <img src={Format} alt="upload" />

                    <img
                      src={Close}
                      className="imgClose"
                      onClick={() => setFile(false)}
                      alt="close"
                    />
                  </div>
                ) : (
                  <>
                    <form>
                      <input
                        type="file"
                        ref={uploadRef}
                        onChange={UploadFile}
                      />
                    </form>
                    <div className="choosebtn">
                      <button>Choose File</button>

                      <span>No File chosen </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {file ? (
              <div>
                <label>
                  <progress ref={progressRef} value="0" max="100" />
                </label>
                <p ref={statusRef}></p>
              </div>
            ) : null}

            {file ? (<div className="buttomLink">
              <Link to={`/received`} className="submitforapprov">Submit for Approval</Link>
            </div>): null}
            
            {/* <p ref={loadTotalRef}></p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadVideo;
