import React, { useRef, useState } from "react";
import "./loadvideo.css";
import Down from "../../images/down.png";
import { Link } from "react-router-dom";
import ApprovedImg from "../../images/approved.png";
import Card from '../../images/card.png';
import Paypal from '../../images/paypal.png';
import Apple from '../../images/apple.png'


const Payment = () => {
  return (
    <div className="thanksContent">
      <div className="container">
        <div className="loadVideo">
          <h1>Complete Your Payment</h1>
          <div className="loadVideoTab">
            <div className="uploadbuttns">
              <span className="checked">
                {" "}
                <img src={Down} alt="imag" />
              </span>{" "}
              <span className="loadVideotext"> Upload video</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="checked notchecked">
                {" "}
                <img src={Down} alt="imag" />
              </span>{" "}
              <span className="loadVideotext">Confiramtion</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="notchecked">3</span>
              <span className="loadVideotext">Completes Payment</span>
            </div>
          </div>
          <div className="paymentBlock">
            <h3>Total Amount Due: <span>$500</span></h3>

            <div className="threeCards">
              <div className="cardPayment">
                <img  src={Card} alt="image"/>
                <p>Card</p>
              </div>
              <div className="cardPayment">
                <img  src={Paypal} alt="image"/>
                <p>PayPal</p>
              </div>
              <div className="cardPayment">
                <img  src={Apple} alt="image"/>
                <p>Apple Pay</p>
              </div>

            </div>
            {/* <Link className="forPayBtn" to="/">Pay Now</Link> */}
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Payment 
