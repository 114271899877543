import React, { useState, useEffect } from "react";
import "./settings.css";
import { useParams, Link } from "react-router-dom";
import SetProfile from "./SetProfile";
import Announcment from "./Announcment";
import Notification from "./Notification";

const SettingsBlock = () => {
  const number = useParams();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const toggleHandel = () => {
    if (number.number=== "settings") {
      setToggleState(1);
    }
    if (number.number=== "announcment") {
      setToggleState(2);
    }
    if (number.number === "notification") {
      setToggleState(3);
    }
  };
 
  useEffect(() => {
    toggleHandel();
  }, [number, toggleState]);

  return (
    <div className="settingsTab">
      <div className="container">
        <div className="tabsGlobal">
          <div className="tabsBlock">
            <Link to={`/tab/settings`}>
              <button
                className={toggleState === 1 ? "tabs active " : "tabs  "}
                onClick={() => toggleTab(1)}
              >
                Settings
              </button>
            </Link>
            <Link to={`/tab/announcment`}>
              <button
                className={toggleState === 2 ? "tabs  active " : "tabs  "}
                onClick={() => toggleTab(2)}
              >
                Announcements
              </button>{" "}
            </Link>
            <Link to={`/tab/notification`}>
              <button
                className={toggleState === 3 ? "tabs active " : "tabs  "}
                onClick={() => toggleTab(3)}
              >
                Notifiactions
              </button>
            </Link>
          </div>
          {toggleState === 1 ? <SetProfile /> : null}
          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <Announcment />
          </div>
          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <Notification />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsBlock;
