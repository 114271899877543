import React, { useRef, useState } from "react";
import "./loadvideo.css";
import Down from "../../images/down.png";
import { Link } from "react-router-dom";
import ApprovedImg from "../../images/approved.png";

const Approved = () => {
  return (
    <div className="thanksContent">
      <div className="container">
        <div className="loadVideo">
          <h1>Your Video Has Been Approved!</h1>
          <div className="loadVideoTab">
            <div className="uploadbuttns">
              <span className="checked">
                {" "}
                <img src={Down} alt="imag" />
              </span>{" "}
              <span className="loadVideotext"> Upload video</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="checked notchecked">
                {" "}
                <img src={Down} alt="imag" />
              </span>{" "}
              <span className="loadVideotext">Confiramtion</span>
            </div>
            <div className="uploadbuttns">
              {" "}
              <span className="notchecked">3</span>
              <span className="loadVideotext">Completes Payment</span>
            </div>
          </div>
          <div className="receivedVideo">
            <div className="receiveContent">
              <div className="approvedImg">
                <img src={ApprovedImg} alt="image" />
              </div>
              <span>
                We’re pleased to inform you that your video has been
                successfully approved by our moderation team! It’s now ready to
                be displayed on the selected billboard.
              </span>
              <h3>To finalize your booking, please complete the payment.</h3>
              <Link className="forPayBtn" to="/payment">Pay Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approved;
