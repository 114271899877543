import React from 'react'
import './advantige.css'

function Advantige() {
  return (
    <section>
        <div className='container'>
            <div className='advantige'>

                 <h2>Main Advantages of LEDs Go</h2>


                 <div className='advantigeContent'>
                    <div className='advantigeFlex'>
                    <div className='advantigeOne'>
                        <h4>Targeting</h4>
                        <p>The advertiser chooses the region and number of the screens in the area by himself through his account, thus reaching desired target audience</p>
                    </div>
                    <div className='advantigeTwo'>
                        <h4>Speed</h4>
                        <p>All the launching process of advertising campaigns takes not more than 48 hours.</p>
                    </div>
                    </div>
                    <div className='advantigeFlex'>
                    <div className='advantigeThree'>
                        <h4>Tracking</h4>
                        <p>Leds Go and efficiency analyses are performed during the advertising campaign. The advertiser can see all the reports in his personal account. Also he can make changes and end the campaign.</p>
                    </div>
                    <div className='advantigeFour'>
                        <h4>Payment for Advertising Spot</h4>
                        <p>The main advantage of the platform is payment for each spot on the screens. This will allow the advertisers to effectively manage their advertising campaigns and use the budgets wisely.</p>
                    </div>
                    </div>
                 </div>
                
                 


            </div>
           


        </div>


    </section>
  )
}

export default Advantige