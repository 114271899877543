import React, { useState } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Eye from "../../images/eye.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ForgotPass = () => {
    const [data, setData] = useState({});
  return (
    <div className="signup">
    <div className="container">
      <Link to={`/`}>
        {" "}
        <img className="singllogo" src={logo} alt="logo" />
      </Link>
    </div>

    <div className="container signUpContent">
      <div className="signUpForm">
        <h1>Forgot Your Password ?</h1>
        <span className="resetPass">Reset password in two quick stepss
        </span>
        <Formik
          initialValues={{
            company: "",
            name: "",
            email: "",
            message: "",
            phoneNumber: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              //   dispatch(getMessage({ values }));
              setData({ values });
              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <div className="formContainer">
              <Form className="form">
                
                <div className="radiobtn">
                <label className="label-form">
                <Field
                  type="radio"
                  name="phone"
                  className="input-radio"
                 
                />
               Phone</label>
               <label className="label-form">
                <Field
                  type="radio"
                  name="phone"
                  className="input-radio"
               
                />
              Email</label>

                </div>
              
                <label className="label-form">Email</label>
                <Field
                  type="email"
                  name="email"
                  className="input-form"
                  placeholder="Enter your email"
                />
                {/* {errors.email && touched.email ? (
                  <div>{errors.email}</div>
                ) : null} */}
               
               
              
             
               
                <button type="submit" className="button-form">
                Reset Pasword
                </button>
              </Form>
            </div>
          )}
        </Formik>
    
      <Link className="linkButtom" to={`/signIn`}>Back</Link>{" "}
      
      </div>
    </div>
  </div>
  )
}

export default ForgotPass
