import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Homepage from "./pages/Homepage";
import Layout from "./pages/Layout";
import Signeup from "./components/Signin/Signeup";
import SignIn from "./components/Signin/SignIn";
import ForgotPass from "./components/Signin/ForgotPass";
import Codefromemail from "./components/Signin/Codefromemail";
import EnterPassword from "./components/Signin/EnterPassword";
import Upload from "./pages/Upload";
import UploadVideo from "./pages/UploadVideo";
import ThanksForSubmit from "./pages/Thanksforsubmit";
import ApprovedVideo from "./pages/ApprovedVideo";
import PaymentComplete from "./pages/PaymentComplete";
import Settings from "./pages/Settings";
import PrivicyPolicy from "./pages/PrivicyPolicy";
import TermsOfUse from "./pages/TermsofUs";
import NotFound from "./pages/NotFound";
import Conntact from "./pages/Conntact";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="upload" element={<Upload />} />
          <Route path="upload/:id" element={<Upload />} />
          <Route path="uploadVideo" element={<UploadVideo />} />
          <Route path="received" element={<ThanksForSubmit />} />
          <Route path="approved" element={<ApprovedVideo />} />
          <Route path="payment" element={<PaymentComplete />} />
          <Route path="tab/:number" element={<Settings />} />
          <Route path="privacypolicy" element={<PrivicyPolicy />} />
          <Route path="termsconditions" element={<TermsOfUse />} />
          <Route path="contact" element={<Conntact/>} />
          <Route path="*" element={<NotFound />} />

        </Route>
        <Route path="signUp" element={<Signeup />} />
        <Route path="signIn" element={<SignIn />} />
        <Route path="forgotpassword" element={<ForgotPass />} />
        <Route path="codefromemail" element={<Codefromemail />} />
        <Route path="newpassword" element={<EnterPassword />} />
      </Routes>
    </>
  );
}

export default App;
