import React from "react";
import Secondheader from "../components/SecondHeader/Secondheader";
import "./main.css";
import Error from "../images/error.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="uploadContent">
      <Secondheader />
      <div className="privicy">
        <div className="container">
          <div className="errorContent">
            <img src={Error} alt="image" />
            <h1>Oops! Page not found</h1>
            <p>We’re sorry, but the page you’re looking for doesn’t exist. It might have been moved or deleted.</p>
             <Link to="/">Go Home page
             </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
