import React, { useState } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Eye from "../../images/eye.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Signeup() {
  const [data, setData] = useState({});
  //   console.log(data)
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirm, setConfirm] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleComfirm = () => setConfirm((value) => !value);
  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>Sign Up</h1>
          <Formik
            initialValues={{
              company: "",
              name: "",
              email: "",
              message: "",
              phoneNumber: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                //   dispatch(getMessage({ values }));
                setData({ values });
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <label className="label-form">Company Name</label>
                  <Field
                    type="text"
                    name="company"
                    className="input-form"
                    placeholder="Enter your company name"
                  />
                  <label className="label-form">Name</label>
                  <Field
                    type="text"
                    name="name"
                    className="input-form"
                    placeholder="Enter your name"
                  />
                  <label className="label-form">Surname</label>
                  <Field
                    type="text"
                    name="surname"
                    className="input-form "
                    placeholder="Enter your surname"
                  />
                  <label className="label-form">Email</label>
                  <Field
                    type="email"
                    name="email"
                    className="input-form"
                    placeholder="Enter your email"
                  />
                  {errors.email && touched.email ? (
                    <div>{errors.email}</div>
                  ) : null}
                 
                   <div className="phonVerify">
                    <div className="verifyPhone">
                  <label htmlFor="phoneNumber" className="label-form ">
                    Phone
                  </label>
                  <Field name="phoneNumber" className="input-form">
                    {({ field }) => (
                      <PhoneInput
                        {...field}
                        country={"ae"}
                        onChange={(phone) =>
                          setFieldValue("phoneNumber", phone)
                        }
                      />
                    )}
                  </Field>
                  </div>
                  {/* <button className="verify">Verify</button> */}
                  </div>
                  {/* <label className="label-form">Verification Code</label>
                  <Field
                    type="text"
                    name="code"
                    className="input-form"
                    placeholder="Enter Code"
                  /> */}
                  <div className="pass">
                    <label className="label-form">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="input-form"
                      placeholder="Enter your password"
                    />
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                  </div>
                  <div className="pass">
                    <label className="label-form">Password</label>
                    <Field
                      type={showconfirm ? "text" : "password"}
                      name="comfirm"
                      className="input-form "
                      placeholder="Confirm your password"
                    />
                    <img src={Eye} alt="image" onClick={toggleComfirm} />
                  </div>
                  <label className="check-form">
                    <Field type="checkbox" name="checkbox" /> I agree with terms
                    of service and the privacy policy from LedSgO
                  </label>

                  <button type="submit" className="button-form">
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
          <p className="formButtom">
            Already have an account ? <Link to={`/signIn`}>Sign In</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signeup;
