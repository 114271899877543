import React from "react";
import "./accordion.css";
import Chevron from "../../images/chevron-up.png";
import ChevronUp from "../../images/mdi_chevron-up.png";
function Accordion({ title, subTitle, isShow, onClick }) {
  return (
    <div className={`accordionBox ${isShow ? "accordBg" : "accordClose"}`} onClick={onClick}>
      <div className="accordionContent">
        <h3 className="accordionTitle ">{title}</h3>
        <div className={isShow ? "accordionChevron" : 'accordionChevronUp'}>
          <img src={isShow ? ChevronUp : Chevron } alt="Icon" />
        </div>
      </div>
      {isShow && <p className="accordionSubtitle">{subTitle}</p>}
    </div>
  );
}

export default Accordion;
