import React, { useState, createContext, useContext } from "react";
import logo from "../../images/logo.png";
import User from "../../images/user.png";
import "./secondheader.css";
import Setting from '../../images/settings.png'
import Anounce from '../../images/announce.png'
import Note from '../../images/notification.png'

import { Link } from "react-router-dom";
const TabContext = createContext(null)
const Secondheader = () => {
  const [user, setUser]= useState(false)
  return (
    <div className="container">
      <div className="secondHeader">
        <div className="secondHeaderLogo">
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="secondHeaderUser">
          <p>Name Surname</p>
          <div className="userName">

            <img className="headerUser" onClick={()=> setUser(prev => !prev)} src={User} alt="user" />
            <div className={`${user ? 'settings': "comentSettings" }`}>
              <Link to={`/tab/settings`}>
            <img className="headersettings" src={Setting} alt="user" />
              <p>Settings</p>
              </Link>
              <Link to={`/tab/announcment`}>
            <img className="headersettings" src={Anounce} alt="user" />
              <p>Announcements</p>
              </Link>
              <Link to={`/tab/notification`}>
            <img className="headersettings" src={Note} alt="user" />
              <p>Notifiactions</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secondheader;
