import React from 'react'
import Approved from '../components/LoadVideo/Approved'
import Secondheader from "../components/SecondHeader/Secondheader";

const ApprovedVideo = () => {
  return (
    <div className='videoBlock'>
        <Secondheader/>
        <Approved/>
      
    </div>
  )
}

export default ApprovedVideo
