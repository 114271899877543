import React, { useState } from "react";
import "./settings.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Frame from "../../images/frame.png";
import { useNavigate, Link, useParams } from "react-router-dom";
import Close from "../../images/closewhite.png";
import Modal from "react-modal";
import Eye from "../../images/eye.png";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "468px",
    border: "1px solid #C060F1",
    borderRadius: "20px",
  },
};
Modal.setAppElement("#root");

const SetContact = () => {
  const [profile, setProfile] = useState(2);
  const [data, setData] = useState({});
  const [showPassword, setshowPassword] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleProf = (index) => {
    setProfile(index);
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTwoIsOpen, setTwoIsOpen] = useState(false);
  const [modalthreeIsOpen, setThreeIsOpen] = useState(false);
  const [modalfourIsOpen, setFourIsOpen] = useState(false);
  const [modalFiveIsOpen, setFiveIsOpen] = useState(false);
  const navigate = useNavigate();

  function openModal() {
    // navigate({ pathname: `/upload/${id}` });
    setIsOpen(true);
  }
  function openSecondModal() {
    // navigate({ pathname: `/upload/${id}` });
    setTwoIsOpen(true);
    // setIsOpen(false);
  }
  function openthierdModal() {
    setThreeIsOpen(true);
  }
  function openfourthModal() {
    setFourIsOpen(true);
  }
  function openfivthModal() {
    setFiveIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#fff";
  }
  function closeSecondModal() {
    setTwoIsOpen(false);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closethiethModal() {
    setThreeIsOpen(false);
  }

  function closefourthModal() {
    setFourIsOpen(false);
  }
  function closefivththModal() {
    setFiveIsOpen(false);
  }

  return (
    <div className={profile === 2 ? "content  active-content" : "content"}>
      <Formik
        initialValues={{
          email: "",
          phoneNumber: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          } else if (!values.phoneNumber) {
            errors.phoneNumber = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            //   dispatch(getMessage({ values }));
            setData({ values });
            setSubmitting(false);
            resetForm();
          }, 400);
        }}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <div className="profilecontact">
            <Form className="profilform">
              <div className="nameSurname">
                <div className="prof">
                  <label className="profilLabel">Email</label>
                  <Field
                    type="email"
                    name="email"
                    autoComplete="off"
                    className="profilInput changeEmail"
                    placeholder="Enter your email"
                  />
                  <img src={Frame} alt="image" onClick={openModal} />
                  {errors.email && touched.email ? (
                    <div className="error">{errors.email}</div>
                  ) : null}
                </div>
                <div className="prof">
                  <label htmlFor="phoneNumber" className="profilLabel">
                    Phone
                  </label>
                  <Field name="phoneNumber" className="profilInput">
                    {({ field }) => (
                      <PhoneInput
                        {...field}
                        country={"ae"}
                        onChange={(phone) =>
                          setFieldValue("phoneNumber", phone)
                        }
                      />
                    )}
                  </Field>
                  <img src={Frame} alt="image" onClick={openfourthModal} />
                  {errors.phoneNumber ? <div>{errors.phoneNumber}</div> : null}
                </div>
              </div>

              <div className="cancelSave">
                <button type="reset" className="profilCancel">
                  Cancel
                </button>
                <button type="submit" className="profilSave">
                  Save
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closeModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Change Email Address
          </h2>
          <p>
            Enter your password and the new email address where we will send an
            email with a confirmation code.
          </p>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              } else if (!values.password) {
                errors.password = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                //   dispatch(getMessage({ values }));
                setData({ values });
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="prof">
                    <label className="profilLabel">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="profilInput"
                      placeholder="Enter your password"
                    />
                    {errors.password && touched.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                  </div>
                  <div className="prof">
                    <label className="profilLabel">Email</label>
                    <Field
                      type="email"
                      name="email"
                      autocomplete="off"
                      className="profilInput "
                      placeholder="Enter your new email"
                    />

                    {errors.email && touched.email ? (
                      <div className="error">{errors.email}</div>
                    ) : null}
                  </div>

                  <div className="cancelSave">
                    <button
                      type="submit"
                      className="profilSave"
                      onClick={openSecondModal}
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        isOpen={modalfourIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closefourthModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closefourthModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Change Phone Number
          </h2>
          <p>
            Enter your mobile phone number. We will send you a text message with
            a confirmation code. Once you enter the code on the next screen,
            your number will be verified.
          </p>
          <Formik
            initialValues={{
          
              phoneNumber: "",
            }}
           
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                //   dispatch(getMessage({ values }));
                setData({ values });
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="prof">
                    <label htmlFor="phoneNumber" className="profilLabel">
                      Phone
                    </label>
                    <Field name="phoneNumber" className="profilInput">
                      {({ field }) => (
                        <PhoneInput
                          {...field}
                          country={"ae"}
                          onChange={(phone) =>
                            setFieldValue("phoneNumber", phone)
                          }
                        />
                      )}
                    </Field>
                  
                    {errors.phoneNumber ? (
                      <div>{errors.phoneNumber}</div>
                    ) : null}
                  </div>

                  <div className="cancelSave">
                    <button
                      type="submit"
                      className="profilSave"
                      onClick={openfivthModal}
                    >
                      Verify with SMS
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        isOpen={modalTwoIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeSecondModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closeSecondModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Verify Your Email</h2>
          <p>
            We sent an email with a verification code to the email address you
            provided.
          </p>
          <Formik
            initialValues={{
              code: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.code) {
                errors.email = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                //   dispatch(getMessage({ values }));
                setData({ values });
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="verification">
                    <label className="profilLabel">
                      Enter Verification Code
                    </label>
                    <Field
                      type="text"
                      name="code"
                      className="profilInput"
                      placeholder="------"
                    />
                    {errors.code && touched.code ? (
                      <div className="error">{errors.code}</div>
                    ) : null}
                  </div>

                  <div className="cancelSave">
                    <button
                      type="submit"
                      className="profilSave"
                      onClick={openthierdModal}
                    >
                      Sent
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>


      <Modal
        isOpen={modalFiveIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closefivththModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closefivththModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Verify Phone Number</h2>
          <p>
          We sent a text message with the verification code to the number 971 4 234 5678

          </p>
          <Formik
            initialValues={{
              code: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.code) {
                errors.email = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                //   dispatch(getMessage({ values }));
                setData({ values });
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="verification">
                    <label className="profilLabel">
                      Enter Verification Code
                    </label>
                    <Field
                      type="text"
                      name="code"
                      className="profilInput"
                      placeholder="------"
                    />
                    {errors.code && touched.code ? (
                      <div className="error">{errors.code}</div>
                    ) : null}
                  </div>

                  <div className="cancelSave">
                    <button
                      type="submit"
                      className="profilSave"
                     
                    >
                      Verify
                    </button>
                  </div>
                </Form>
                <Link to={`/signIn`}>Sign In
                </Link>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        isOpen={modalthreeIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closethiethModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closethiethModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Change Email Address
          </h2>
          <p>Your email address has been changed successfully.</p>

          <div className="profilecontact">
            <div className="profilform">
              <div className="cancelSave">
                <button
                  type="text"
                  className="profilSave"
                  onClick={closethiethModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SetContact;
