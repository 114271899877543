import React, { useState } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import Eye from "../../images/eye.png";

import "react-phone-input-2/lib/style.css";

const SignIn = () => {
    const [data, setData] = useState({});
  //   console.log(data)
  const [showPassword, setshowPassword] = useState(false);
 
  const toggleChecked = () => setshowPassword((value) => !value);

  return (
    <div className="signup">
    <div className="container">
      <Link to={`/`}>
        {" "}
        <img className="singllogo" src={logo} alt="logo" />
      </Link>
    </div>

    <div className="container signUpContent">
      <div className="signUpForm">
        <h1>Sign In</h1>
        <Formik
          initialValues={{
            company: "",
            name: "",
            email: "",
            message: "",
            phoneNumber: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              //   dispatch(getMessage({ values }));
              setData({ values });
              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <div className="formContainer">
              <Form className="form">
                
           
              
                <label className="label-form">Email</label>
                <Field
                  type="email"
                  name="email"
                  className="input-form"
                  placeholder="Enter your email"
                />
                {/* {errors.email && touched.email ? (
                  <div>{errors.email}</div>
                ) : null} */}
               
               
              
                <div className="pass">
                  <label className="label-form">Password</label>
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="input-form"
                    placeholder="Enter your password"
                  />
                  <img src={Eye} alt="image" onClick={toggleChecked} />
                </div>
             
               <Link to={`/forgotpassword`} className="formlink">
                Forgot your password ?
              
                </Link>
                <button type="submit" className="button-form">
                  Sign In
                </button>
              </Form>
            </div>
          )}
        </Formik>
        <p className="formButtom">
        Don’t have an account?  <Link  to={`/signUp`}>Sign Up</Link>{" "}
        </p>
      </div>
    </div>
  </div>
  )
}

export default SignIn
