import React from 'react'
import Secondheader from "../components/SecondHeader/Secondheader";
import LoadVideo from '../components/LoadVideo/LoadVideo';
const UploadVideo = () => {
  return (
    <div className='videoBlock'>
        <Secondheader />
     <LoadVideo/>
    </div>
  )
}

export default UploadVideo
