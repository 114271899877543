import React from 'react'
import Secondheader from "../components/SecondHeader/Secondheader";
import ThanksFor from '../components/LoadVideo/Thanks';

const ThanksForSubmit = () => {
  return (
    <div className='videoBlock'>
        <Secondheader />
        <ThanksFor/>
 
    </div>
  )
}

export default ThanksForSubmit
