import React from "react";
import logo from "../../images/logo.png";
import back from "../../images/footer.png";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <img className="footerBackground" src={back} alt="image" />
      <div className="bigContainer ">
        <div className="container ">
          <div className="footer">
            <img className="logo" src={logo} alt="logo" />

            <div className="footerLinks">
              <div className="footerContent ">
                <Link to={`/privacypolicy`}>Privacy Policy</Link>
                <Link to={`/termsconditions`}>Terms & Conditions</Link>
                <Link to={`/contact`}>Contact Us</Link>
              </div>
              <div className="footerContent footerRight ">
                <p>Follow Us :</p>
                <Link to={`#`}>Instagram</Link>
                <Link to={`#`}>Tik Tok</Link>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p>© 2024 Led’s Go . All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
