import React from "react";
import Secondheader from "../components/SecondHeader/Secondheader";
import FindBilboard from "../components/FindBillboard/FindBilboard";
import './main.css'
const Upload = () => {
  return (
    <div className="uploadContent">
      <Secondheader />
      <FindBilboard />
    </div>
  );
};

export default Upload;
